<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12 flex flex-col is-relative">
      <b-loading
        :is-full-page="false"
        v-model="$store.getters.isProcessing"
      ></b-loading>
      <div class="flex items-center mb-4">
        <b-button
          class="mr-4"
          type="is-light"
          tag="router-link"
          :to="{ name: 'Roles' }"
          >Back</b-button
        >
        <div class="is-size-4">{{ $route.name }}</div>
      </div>
      <div class="px-4 flex-grow flex">
        <div class="-mx-6 flex justify-center flex-wrap items-center flex-grow">
          <div class="px-2 w-8/12">
            <div class="p-4 border">
              <b-field
                label="Name"
                :type="$store.getters.hasErrors('name').type"
                :message="$store.getters.hasErrors('name').message"
              >
                <b-input v-model="name"></b-input>
              </b-field>
              <Slugger
                :data="name"
                :saved_slug="savedSlug"
                table="roles"
                column="slug"
                :errors="$store.getters.hasErrors('slug')"
                @on-slug-change="slug = $event"
              />
              <b-field label="Permissions"></b-field>
              <div class="border p-4 mb-4 h-64 overflow-y-scroll">
                <b-field v-for="(item, index) in permissions" :key="index">
                  <b-checkbox
                    v-model="checkedPermissions"
                    :native-value="item.id"
                    >{{ item.name }}</b-checkbox
                  >
                </b-field>
              </div>
              <div class="flex items-center justify-end">
                <b-button
                  class="mr-4"
                  v-if="$route.params.id"
                  type="is-danger is-light"
                  :disabled="$store.getters.isProcessing"
                  @click="onDelete()"
                  >Delete</b-button
                >
                <b-button
                  type="is-success"
                  :disabled="$store.getters.isProcessing"
                  @click="onSave"
                  >Save changes</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import Slugger from "@/components/admin/Slugger";
import store from "@/store";
export default {
  components: {
    Sidebar,
    Slugger
  },
  data: () => ({
    name: "",
    slug: "",
    savedSlug: "",
    permissions: [],
    checkedPermissions: []
  }),
  computed: {},
  watch: {},
  methods: {
    onSave() {
      store.dispatch("saveRolesForm", {
        name: this.name,
        slug: this.slug,
        id: this.$route.params.id || false,
        ids: this.checkedPermissions
      });
    },
    onDelete() {
      store.dispatch("deleteSelection", {
        message: `Do you really want to delete this role?`,
        ids: [this.$route.params.id],
        path: "roles",
        push: "Roles"
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store
        .dispatch(to.meta.dispatch, { id: to.params.id || null })
        .then(res => {
          next(vm => {
            vm.permissions = res.data.permissions;
            if (to.params.id) {
              vm.name = res.data.role.name;
              vm.slug = res.data.role.slug;
              vm.savedSlug = res.data.role.slug;
              vm.checkedPermissions = _.map(res.data.role.permissions, "id");
            }
          });
        });
    }
  }
};
</script>
